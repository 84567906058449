import * as React from "react"
import { StaticImage } from "gatsby-plugin-image"

import "./bulma.scss"

import Layout from "../components/layout"
import Seo from "../components/seo"

const HomePage = () => (
    <Layout>
        <Seo title="Startseite" />

        <div className="section">
            <div className="container is-max-desktop">

                <div className="columns">
                    <div className="column mt-6">
                        <div className="content is-medium line-height-large">
                            <h2 className="has-text-weight-normal">Der Körper ist der Ausdruck der Seele</h2>
                            <p>
                                Für mich ist der menschliche Körper ein komplexes, gut organisiertes System, welches
                                durch viele verschiedene Aspekte (körperliche Überlastung, Stress, Arbeit, Familie,
                                Ernährung, etc.) beeinflusst wird. Die Reaktion auf solche Einflüsse können sich in&nbsp;
                                <span className="mark">körperlichen Dysbalancen</span>, Beschwerden und Erkrankungen äußern.</p>
                            <p>
                                In jedem Menschen stecken aber <span className="mark2">eigene Ressourcen</span> und Energien, welche eine&nbsp;
                                <span className="mark">Selbstheilung ermöglichen</span>.
                            </p>
                            <p>
                                Sie auf dem <span className="mark">Weg der Entdeckung und Mobilisierung Ihrer eigenen Kräfte zu begleiten</span>,&nbsp;freue ich mich.
                            </p>
                        </div>
                    </div>
                </div>

                <div className="columns margin-top-large is-flex is-flex-direction-column-reverse-x">
                    <div className="column is-8">
                        <div className="content is-medium has-text-right-tablet line-height-large">
                            <h2>Terminanfragen</h2>
                            <p>Anfragen können Sie gern telefonisch oder per Mail vereinbaren. Mein Bestellsystem (Termin-Management) ist so eingerichtet, dass kaum Wartezeiten entstehen. Durch die großzügige eingeplante Behandlungszeit möchte ich Ihnen eine stressfreie, so wie auf ihre Bedürfnisse abgestimmte Therapie gewährleisten.</p>
                        </div>
                    </div>
                    <div className="column is-4 is-align-self-center has-text-centered">
                        <StaticImage
                            src="../images/calendar-4.png"
                            alt="Kalenderblatt (Icon in schwarz weiß)"
                            style={{ maxWidth: "150px" }}
                        />
                        <div data-spacer className="mb-6 is-hidden-tablet"></div>
                    </div>
                </div>

                <div className="columns margin-top-large">
                    <div className="column is-4 is-align-self-center has-text-centered">
                        <StaticImage
                            src="../images/physical-therapy-4.png"
                            alt="Physiotherpeut behandelt Patienten (Icon in schwarz weiß)"
                            style={{ maxWidth: "180px" }}
                        />
                        <div data-spacer className="mb-6 is-hidden-tablet"></div>
                    </div>
                    <div className="column is-8">
                        <div className="content is-medium line-height-large">
                            <h2>Der 1. Termin</h2>
                            <p>Die erste Therapieeinheit dient vor allem dem Kennenlernen von Ihnen mit ihrem Anliegen durch eine ausführliche Befunderhebung. Daraus leiten wir gemeinsam einen auf Sie abgestimmten Behandlungsplan ab, welcher die Basis für die folgenden Behandlungseinheiten ist.</p>
                        </div>

                    </div>
                </div>
            </div>

            <div className="container homepage-tile margin-top-large">
                <div className="tile is-ancestor">
                    <div className="tile is-vertical is-7">
                        <div className="tile">
                            <div className="tile is-parent is-vertical">
                                <article className="tile is-child">
                                    <StaticImage
                                        src="../images/praxis/21-10-27_10-21-23_DSCF2893.jpg"
                                        alt="Wartezimmer mit 2 Stühlen in der Praxis mitbewegt in Schleußig, Leipzig"
                                        aspectRatio={3 / 4}
                                    />
                                </article>
                            </div>
                            <div className="tile is-parent">
                                <article className="tile is-child">
                                    <StaticImage
                                        src="../images/praxis/21-11-01_09-25-29_DSCF3029.jpg"
                                        alt="Glaskaraffe mit 'Lebendem Wasser' im Wartezimmer"
                                        aspectRatio={3 / 4}
                                    />
                                </article>
                            </div>
                        </div>
                        <div className="tile is-parent">
                            <article className="tile is-child">
                                <StaticImage
                                    src="../images/praxis/21-10-27_10-06-52_DSCF2879.jpg"
                                    alt="Behandlungsraum 'Wald' mit Liege"
                                    aspectRatio={10 / 12}
                                    transformOptions={{
                                        cropFocus: "bottom"
                                    }}
                                />
                            </article>
                        </div>
                    </div>
                    <div className="tile is-vertical">
                        <div className="tile">
                            <div className="tile is-parent is-vertical">
                                <article className="tile is-child">
                                    <StaticImage
                                        src="../images/praxis/21-10-27_10-15-56_DSCF2888.jpg"
                                        alt="Behandlungsraum 'Meer' mit Liege"
                                        aspectRatio={8 / 11}
                                    />
                                </article>
                            </div>
                        </div>
                        <div className="tile is-parent">
                            <article className="tile is-child">
                                <StaticImage
                                    src="../images/praxis/21-10-27_09-56-25_DSCF2872.jpg"
                                    alt="Gymnastik Raum mit Spiegel, Sprossenwand und Physiotherapie Equipment (Hanteln, Fußmatten, Balance-Kissen)"
                                    aspectRatio={4 / 5}
                                />
                            </article>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    </Layout >
)

export default HomePage
